import {IconButton, Typography} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import * as fuseActions from "app/store/actions";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {PROJECT_NAME_FOR_CHECKIN_RESULTS} from "../../utils/constent";

function Mainheader() {
    const [name, setName] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();

    let {projectId} = useParams();

    const checkinResults = useSelector(({externalM}) => externalM.checkin_Results.checkinResults);
    const checkinResultsDialog = useSelector(({fuse}) => fuse.dialogs["checkinResults"]);

    useEffect(() => {
        if (projectId) {
            const t = localStorage.getItem(`${PROJECT_NAME_FOR_CHECKIN_RESULTS}`);
            setName(t);
        }
    }, [projectId]);

    const handleGoback = useMemoizedFn((e) => {
        e.stopPropagation();
        e.preventDefault();
        if (checkinResultsDialog) dispatch(fuseActions.turnOff("checkinResults"));
        else history.goBack();
    });

    return (
        <div className="flex flex-row items-center">
            <MyTooltip title={<Translate id={"exm.back"} />}>
                <IconButton className={"mx-3"} onClick={handleGoback} aria-label="back icon button">
                    <Icon>{checkinResultsDialog ? "close" : "arrow_back"}</Icon>
                </IconButton>
            </MyTooltip>

            {
                <Translate>
                    {({translate}) => (
                        <Typography variant={"h5"} className={"flex items-center"}>
                            {/*<Translate id="exm.CheckInResults"/>*/}
                            {projectId ? translate("exm.CheckInResults with params", {name: name || projectId}) : translate("exm.CheckInResults")}
                            <small className={"ml-2"}>({checkinResults ? checkinResults.length : 0})</small>
                        </Typography>
                    )}
                </Translate>
            }
        </div>
    );
}

export default Mainheader;
