import {useMemoizedFn} from "ahooks";
import {useEffect, useRef} from "react";
import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {FusePageSimple} from "../../../../../@fuse";
import {useEXManageStore} from "../../state/useEXManageStore";
import {getCheckinResults} from "../../store/actions";
import sources from "../../translations/external_management.json";
import {FULL_SCREEN_IMAGE_DIALOG} from "../../utils/constent";
import EXImageGallery from "../dialogs/EXImageGallery";
import FullImageDialog from "../dialogs/FullImageDialog";
import ExternalList from "./ExternalList";
import MainHeader from "./MainHeader";

function CheckinResultsPage(props) {
    const dispatch = useDispatch();

    let params = useParams();

    const openGallery = useEXManageStore((state) => state.openGallery);

    const cacheProjectId = useSelector(({fuse}) => fuse.dialogs["cacheProjectId"]);

    const projectId = params.projectId || cacheProjectId;

    const ref = useRef(null);

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    useEffect(() => {
        props.addTranslation(sources);
    }, []);

    useEffect(() => {
        fetchDatas();
    }, [projectId]);

    const fetchDatas = useMemoizedFn(() => {
        const params = {
            projectId: projectId,
        };
        dispatch(getCheckinResults(params));
    });

    return (
        <FusePageSimple
            sidebarInner
            header={<MainHeader pageLayout={ref} />}
            content={
                <>
                    <ExternalList />
                    {dialogs[FULL_SCREEN_IMAGE_DIALOG] && <FullImageDialog />}
                    {openGallery && <EXImageGallery />}
                </>
            }
            ref={ref}
        />
    );
}

export default withLocalize(CheckinResultsPage);
